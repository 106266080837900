:root {
  --primary: #1c2038;
  --chartbg: #252b48;
  --grey: #393b4c;
  --light: #e8e8e8;
  --green: #41b883;
  --sidebar-width: 250px;
  --hover: rgba(71, 80, 77, 0.137);
}


/* DATA CHART CSS */
* {
  letter-spacing: "1.2px";
}

#datarow {
  /* width: 28rem;
  margin: -1.3rem 0 0 1.5rem;
  line-height: 1.9rem; */
  height: 100%;
}

#datarow p {
  font-size: 16px;
  text-align: center;
}

#datarow .col .card {
  background-color: var(--chartbg);
  color: var(--light);
  font-size: 10px;
  height: 26vh;
}

#datarow .col .card .card-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 24px;
  text-align: center;
}

#datarow .col .card .card-subtitle {
  font-size: 10px;
}

#datarow .col .card .card-footer {
  border: none;
  padding: 0;
  font-size: 12px;
}

#datarow .card .negative-change {
  color: #e50914 !important;
}

#datarow .card .positive-change {
  color: rgb(21, 168, 49);
}

/* DATA CHART RESPONSIVENESS */

@media only screen and (max-width: 1024px) and (max-width: 1400px) { 
  #datarow p {
  font-size: 13px;
  text-align: center;
}
#datarow .col .card {
  height: 100%;
}
  #datarow .col .card .card-subtitle {
  font-size: 10px;
}
  #datarow .col .card .card-footer {
  font-size: 8px;
}
  #datarow .col .card .card-title {
    font-size: 24px;
    margin-top: 16px;
}
  #datarow .col .card .card-subtitle {
    font-size: 10px;
}
}

@media only screen and (min-width: 1030px) and (max-width: 1440px) { 
  #datarow .col .card {
    height: 100%;
  }
}



/* LINE CHART */

 #linechart * {
  height: 100% !important;
  width: 100% !important;
  min-height: 0 !important;
}

#linechart {
  background-color: var(--chartbg);
  padding: 30px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  max-height: 55vh;
} 


#linechart button {
  color: #41b883;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  text-align: right;
  margin-top: -40px;
  font-size: 11px;
  position: inherit;
}

/* DOUGHNUT CHART */

#donutchart {
  height: 550px!important;
  margin: 0 2rem 0 2rem;
  width: 550px!important;
  /* position: relative; */
}


/* CIRCULAR PROGRESS */

.CircularProgressbar {
  width: 60% !important;
  height: 80%;
  justify-content: center;
  display: flex;
  margin: auto;
  padding: 0px;
  margin-top: 3px;
}

/* BAR CHART */

#barchart {
  display: block;
  box-sizing: border-box;
  height: 500px;
  width: 800px !important;
  margin-left: 2.5rem;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05));
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.export-link {
  color: #41b883; 
  text-decoration: none;
  font-size: 14px; 
  margin-right: 16px;
  margin-bottom: 1rem;
  padding-top: -100px;
}




/* ACTIVITY MOBILE */

#mobile-activity {
  height: 50% !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05));
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 24px;
  width: 400px;
}


/* SPINNER */

.spinner-border {
  width: 24px;
  height: 24px;
  margin-top: 16px;
}